(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let region_svg = selector.find("[data-region-svg]");
        let region_list = selector.find("[data-region-list]");

        let tooltip = selector.find(".tooltip");

        window.onmousemove = function (e) {
            var x = (e.clientX + 20) + 'px',
                y = (e.clientY + 20) + 'px';
            tooltip.css("top",y).css("left",x);
        };

        region_svg.find("[data-region]").hover(function(){
            let elm = $(this);
            let id = elm.addClass("state--hover").data("region");
            region_list.find(`[data-region="${id}"]`).addClass("state--active");
            tooltip.find(".part_ui_title").text(region_list.find(`[data-region="${id}"] .part_ui_title`).text());
            tooltip.find(".part_ui_heading").text(region_list.find(`[data-region="${id}"] .part_ui_heading`).text());
            tooltip.show();
        }, function(){
            let elm = $(this);
            let id = elm.removeClass("state--hover").data("region");
            region_list.find(`[data-region="${id}"]`).removeClass("state--active");
            tooltip.hide();
        });

        win.on("scroll", function(){
            if ('ontouchstart' in document.documentElement) {
                region_svg.find("[data-region]").removeClass("state--hover");
                region_list.find(`[data-region]`).removeClass("state--active");
                tooltip.hide();
            }
        });

        window.lui[name] = comp;
    }
})("comp_base_map");