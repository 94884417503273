(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        $.importScript(cdnjs.twentytwenty, function(){
            selector.find(".twentytwenty-container").twentytwenty({
                defaultOffsetPct: 0.5
            });
        });

        window.lui[name] = comp;
    }
})("comp_base_compare");