(function($) {
    doc.on("change", "[data-croppie-upload]", function (e) {
        let elm = $(this);
        $.importScript(cdnjs.croppie, function(){
            let files = elm[0].files;
            let f = files[0];
            let croppie_area = $("[data-croppie-area]");

            let uploadCrop;

            if (!f.type.match('image.*')) {
                return false;
            }

            let reader = new FileReader();

                let width = croppie_area.data("croppie-area")[0];
                let height = croppie_area.data("croppie-area")[1]

                reader.onload = (function() {
                    return function(e) {
                        let image = e.target.result;
                        croppie_area.html(`<img src="${image}" />`);
                        croppie_area.parent().addClass("state--active");
                        uploadCrop = new Cropper(croppie_area.find("img")[0], {
                            aspectRatio: width / height,
                            responsive: true,
                            maxCropBoxHeight: 400
                        });

                        doc.one("submit", ".part_form_theme", function (e) {
                            e.preventDefault();

                            $("[data-theme-toggle]").removeClass("state--active");
                            $("[data-theme-image]").attr("xlink:href", uploadCrop.getCroppedCanvas().toDataURL());

                            nl_lib_dialog.close();
                        });
                    };
                })(f);

            reader.readAsDataURL(f);
        });
    });
})(jQuery);