(function($) {
    if (!html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("state--invalid state--valid");
            if ($(this).find("input, textarea").val() !== "") {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("state--valid");
                } else {
                    $(this).addClass("state--invalid");
                }
            }
        };

        doc.on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });

        doc.on("change", ".part_ui_input.type--file input", function(){
            let elm = $(this);

            elm.closest(".part_ui_input").addClass("state--valid").attr("aria-label",$(this).val().replace(/.*(\/|\\)/, ''));
        });

        $(".part_ui_input").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                $(this).validation();
            }
        });
    }
})(jQuery);