let cdnjs = {
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.11/js/lightgallery-all.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.min.js",
    "autosize": "https://cdnjs.cloudflare.com/ajax/libs/autosize.js/4.0.2/autosize.min.js",
    "parallax": "https://cdnjs.cloudflare.com/ajax/libs/parallax/3.1.0/parallax.min.js",
    "flickity": "https://cdnjs.cloudflare.com/ajax/libs/flickity/2.1.2/flickity.pkgd.min.js",
    "twentytwenty": "https://cdnjs.cloudflare.com/ajax/libs/mhayes-twentytwenty/2.0.0-beta.1/jquery.twentytwenty.min.js",
    "croppie": "https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.4.1/cropper.min.js",
    "rellax": "https://cdnjs.cloudflare.com/ajax/libs/rellax/1.7.0/rellax.min.js"
};