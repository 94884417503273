(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        selector.on("click", "[data-theme-toggle]", function(){
            let elm = $(this);
            let image = elm.data("theme-toggle");

            elm.addClass("state--active").closest(".col").siblings(".col").find(".elm_item").removeClass("state--active");
            elm.closest(".wrp_comp_body").find("[data-theme-image]").attr("xlink:href", image);
        });

        window.lui[name] = comp;
    }
})("comp_base_themes");