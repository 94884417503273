var reInitGCaptcha;

(function($) {
    fn_nl_lib_anchor();

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LcfQ5MUAAAAAAKgHMhZk5Jo36a91oBrqc4nt-Eq").done(function () {
            grecaptcha.ready(function () {

                $('form[data-recaptcha]').each(function() {
                    let form = $(this);
                    let type = $(this).attr('data-recaptcha');

                    grecaptcha.execute('6LcfQ5MUAAAAAAKgHMhZk5Jo36a91oBrqc4nt-Eq', {action: type})
                        .then(function (token) {
                            form.find('[data-g-token]').val(token);
                        });

                });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    }

    doc.on('change','select#form-subject',function() {

        let url = $(this).attr('data-ajax-link');
        let type = $(this).val();
        let message = $('#form-message').val();

        $.ajax({
            method: 'post',
            url: url,
            data: {type: type, message: message},
            dataType: "json",
            cache: false,
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    sr.sync();
                });
            }
        });
    });

    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);
            
        var submitButton = $(this).find('button[type="submit"]');
        if(submitButton.length) {
            submitButton.attr('disabled','disabled');
            submitButton.addClass('mod--loading');
        }

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    sr.sync();
                    
                    if(submitButton.length) {
                        setTimeout(function() {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        },3000);
                    }

                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }

                });
            }
        });
    });

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    nl_lib_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s[0]).length) {
                window.lui.lib[key](dialog_selector.find(s[0]),s[1])
            }
        });
    });

    $.libInit(".part_ui_link, .part_ui_checkbox, .part_ui_radio", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit("[data-lib-switch]", "lib_switch", function(selector) {
        $(selector).nl_lib_switch();
    });

    $.libInit("textarea", "lib_autosize", function(selector) {
        $.importScript(cdnjs.autosize, function(){
            cssLoaded(function(){
                autosize($(selector));
            });
        });
    });

    $.libInit(".lib--rellax", "lib_rellax", function() {
        if (win.width() > 960) {
            $.importScript(cdnjs.rellax, function() {
                new Rellax(".lib--rellax", {
                    center: true
                });
            });
        }
    });


    if (body[0].style["mix-blend-mode"] === undefined) {
        html.addClass("no-blend-mode")
    }

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();
    
    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }
    
    $('[data-id="button_'+$('[data-nummero]').data('nummero')+'"]').click();
    
})(jQuery);