(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let gallery = selector.find("[data-gallery]");

        if (gallery.length) {
            $.lib_flickity(function(){

                let slider = selector.find("[data-slider]");

                slider.flickity({
                    groupCells: '25%',
                    cellAlign: 'center',
                    contain: true,
                    pageDots: false,
                    wrapAround: false,
                    autoPlay: false,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: true
                });

                $.importScript(cdnjs.lightgallery, function(){
                    gallery.lightGallery({
                        thumbnail: true,
                        exThumbImage: 'data-exthumbimage',
                        fullScreen: false,
                        zoom: true,
                        actualSize: false,
                        hash: true,
                        download: true,
                        autoplay: false,
                        autoplayControls: true
                    });
                    doc.on("click", "[data-gallery-open]",function(){
                        $(this).closest(".part_item_gallery").find("[data-gallery]").children("a:first-of-type").trigger("click");
                    });
                });

                selector.on("click", "[data-slider-nav]", function() {
                    let elm = $(this);
                    let elm_dir = elm.data("slider-nav");

                    if (elm_dir === "prev") {
                        slider.flickity('previous');
                    } else {
                        slider.flickity('next');
                        console.log("asd");
                    }
                });

                function fn_arrows() {
                    let items_lenght =  slider.find(".flickity-slider").children().length;
                    let items_width = slider.find(".flickity-slider").children().outerWidth();
                    let width = slider.find(".flickity-slider").width();

                    if (width >= (items_lenght * items_width)) {
                        selector.find("[data-slider-nav]").hide();
                    } else {
                        selector.find("[data-slider-nav]").show();
                    }
                }

                win.on("resize", fn_arrows);
                fn_arrows();
            });
        }

        window.lui[name] = comp;
    }
})("comp_base_gallery_slider");