(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-slider]");
        let autoplay = slider.data("slider");

        $.lib_flickity(function(){
            if (slider.length) {
                slider.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    autoPlay: autoplay,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: false,
                    draggable: false,
                    initialIndex: 0
                });
            }
        });

        let header = $("#layout_header");
        let switcher = $("[data-switch-cycle]");

        selector.on({
            "mouseenter": function () {
                slider.flickity('pausePlayer');
            },
            "mouseleave": function (e) {
                slider.flickity('unpausePlayer');
            }
        }, ".wrp_comp_switch");

        selector.on("change", "[data-switch-cycle]", function(){
            let elm = $(this);

            if (elm.prop("checked") === true) {
                slider.flickity( 'select', 0 );
                header.removeClass("invert--colors");
                header.addClass("state--inverting");
            } else {
                slider.flickity( 'select', 1 );
                header.addClass("invert--colors");
                header.addClass("state--inverting");
            }
        });

        slider.on( 'change.flickity', function( event, index ) {
            if (index === 0) {
                switcher.prop("checked", true);
                header.removeClass("invert--colors");
                header.addClass("state--inverting");
            } else {
                switcher.prop("checked", false);
                header.addClass("invert--colors");
                header.addClass("state--inverting");
            }
        });

        slider.on( 'settle.flickity', function (event, index) {
            header.removeClass("state--inverting");
        });

        window.lui[name] = comp;
    }
})("comp_base_visual_cycle");