(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        let header = fn.find(".wrp_header_body");

        fn.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_body_logo").clone(),
            nav = header.find(".elm_body_nav").clone();

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').slideAndSwipe();
        });

        $.importScript(cdnjs.headroom, function(){
            let comp_text_about = $(".comp_text_about");
            let comp_text_about_image = $(".comp_text_about_image");

            let headroom = new Headroom(fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                },
                onTop : function() {
                    if (comp_text_about.length) {
                        comp_text_about.find(".wrp_comp_head").removeClass("state--active");
                    }
                    // if (comp_text_about_image.length) {
                    //     comp_text_about_image.find(".elm_body_block").removeClass("state--active");
                    // }
                },
                onNotTop : function() {
                    if (comp_text_about.length) {
                        comp_text_about.find(".wrp_comp_head").addClass("state--active");
                    }
                    // if (comp_text_about_image.length) {
                    //     comp_text_about_image.find(".elm_body_block").addClass("state--active");
                    // }
                }
            });
            headroom.init();
        });
    }
})(jQuery);